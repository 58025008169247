import React, { useState, useEffect } from 'react';
import Header from '../../Component/Header/header';
import Footer from '../../Component/Footer/footer';
import { Container, Row, Col } from 'react-bootstrap';
import { FirstCard } from '../Component/TournamentCard/tournamentCard';
// import MyPagination from '../../Component/MyPagination/pagination';
import { APIGet, APIPost } from '../../helpers/api';
import ScrollToTopButton from '../Component/ScrollButton/scrollButton';
import './viewDetail.scss';
import '../View-Detail-Page/viewDetail.css'
import Spinner from 'react-bootstrap/Spinner'
import detailImg from '../../Img/images/view-details.png';
import Loader from '../../Component/Loader';


const ViewDetail = (props) => {

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [spinner, setSpinner] = useState(false)
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1)
	const [state, setState] = useState([]);
	const itemsPerPage = 10;
	const [formData, setFormData] = useState({
		keyword: '',
		Title: '',
		Type: '',
		Start_Date: '',
		End_Date: '',
		state: '',
		city: ''
	});

	const resetForm = () => {
		setFormData({
			keyword: '',
			Title: '',
			Type: '',
			Start_Date: '',
			End_Date: '',
		});
	};


	const callApi = async () => {
		try {
			const baseUrl = process.env.REACT_APP_BASE_URL;
			const response = await APIPost(baseUrl, 'get-games-Managed', {
				params: {
					page: page,
					limit: itemsPerPage,
					// Add other parameters as needed
				},
				filter: {
					keyword: '',
					Title: '',
					Type: '',
					Start_Date: '',
					End_Date: '',
					state: '',
					city: ''
				},
				Page_Number: page,
				Organizer_id: 0,
				Page: 'O',
			});


			const responseData = response.data.data;
			console.log(totalPages
				, "responce data")
			setData(responseData);
			//   setTotalPages(responseData.Stats.total);

			setTotalPages(Math.ceil(responseData.Stats.total / itemsPerPage));
			setLoading(false);

		} catch (error) {
			setLoading(false);
			console.error('Error fetching data:', error);
		}
	};

	useEffect(() => {
		getStateCountry()
		if (loading) {
			callApi();
		}
	}, [loading, page, itemsPerPage]);

	useEffect(() => {
		callApi();
	}, [page, itemsPerPage]);
	useEffect(() => {
		setFormData({ ...formData, city: '' })
	}, [formData?.state])

	const getStateCountry = async () => {
		try {
			const baseUrl = process.env.REACT_APP_BASE_URL;
			const response = await APIGet(baseUrl, 'get-state-city', {})
			setState(response.data);
		} catch (error) {
			console.error('Error:', error);
		}
	}




	const handlePageChange = (newPage) => {
		setPage(newPage);
	};

	const [apiResponse, setApiResponse] = useState(null);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	console.log(formData.keyword, "formData.keyword")

	const handleSearch = async () => {
		const selectedType = formData.Type === '1' ? 'T' : formData.Type === '2' ? 'F' : '';
		const keyword = formData.keyword === 'complete' ? 'Complete' : formData.keyword === 'upcoming' ? 'Upcoming' : 'Current';
		setSpinner(true)

		try {
			const baseUrl = process.env.REACT_APP_BASE_URL;
			const stateItem = state.find(item => item.selected_State.isoCode === formData.state);
			const response = await APIPost(baseUrl, 'get-games-Managed', {
				filter: {
					keyword: keyword,
					Title: formData.Title,
					Type: selectedType,
					Start_Date: formData.Start_Date,
					End_Date: formData.End_Date,
					state: stateItem?.selected_State_name || '',
					city: formData.city
				},
				Organizer_id: 0,
				Page_Number: 1,
				Page: 'O',
			});
			setData(response.data.data);
			setSpinner(false)
		} catch (error) {
			setSpinner(false)
			console.error('Error:', error);
		}
	};



	return (
		<>
			<ScrollToTopButton />
			<Header />
			<section className=" veiw-tournaments pb-5">
				<Container>
					<Row className="align-items-center">
						<div className="col-lg-12 text-center pt-5  mt-5 mb-4">
							<h1><b>More Leagues/Tournaments</b></h1>
						</div>
					</Row>

					<div className="download-the-app mb-5">
						<Container>
							<Row>
								{/* <Filter headingText="Leagues/Tournaments" /> */}
								<Col lg="12" xs="12">
									<div className="inner-wrap details-views details-teams">

										<div className="content">
											<div className="mb-2 text-start">
												<label htmlFor="exampleFormControlInput1" className="form-label">Title</label>
												<input type="text" className="form-control mb-2" id="exampleFormControlInput1"
													placeholder="Title" name="Title" value={formData.Title} onChange={handleInputChange} />
											</div>
										</div>
										<div className="date">
											<label className="mb-2" htmlFor="start">Start date:</label>
											<input type="date" id="start" className="form-control mb-2" name="Start_Date"
												placeholder="Date" value={formData.Start_Date} onChange={handleInputChange} />
										</div>
										<div className="date">
											<label className=" mb-2" htmlFor="close">End date:</label>
											<input type="date" id="close" className="form-control mb-2" name="End_Date"
												placeholder="Date" value={formData.End_Date} onChange={handleInputChange} />
										</div>

										<div className="select-view ">
											<label className="mb-2 ">State </label>
											<select name="state" id="yourSelectId" value={formData.state} onChange={handleInputChange}>
												<option value=''>Choose...</option>
												{state && Array.from(new Set(state.map(item => item.selected_State.isoCode))).map(isoCode => {
													const stateItem = state.find(item => item.selected_State.isoCode === isoCode);
													return (
														<option key={stateItem.selected_State.isoCode} value={stateItem.selected_State.isoCode}>{stateItem.selected_State_name}</option>
													);
												})}
											</select>
										</div>
										<div className="select-view ">
											<label className="mb-2 ">City </label>
											<select name="city" id="yourSelectId2" value={formData.city} onChange={handleInputChange}>
											<option value=''>Choose...</option>
													{state && formData.state && state.filter(item => item.selected_State.isoCode === formData.state)?.map(item=>
														<option key={item.selected_City_name} value={item.selected_City_name}>
															{item.selected_City_name}
														</option>
													)}
											</select>
										</div>
										<div className="search-btn mt-3 pt-3">
											<button type="button" className="btn btn-primary" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass me-2"></i> {spinner ? <> <Spinner animation="border" role="status">
											</Spinner></> : <>Search</>} </button>
											{apiResponse && (
												<div>
													<h2>API Response:</h2>
													<pre>{JSON.stringify(apiResponse, null, 2)}</pre>
												</div>
											)}
										</div>
									</div>
								</Col>
							</Row>
						</Container>
						{
							data?.games?.length > 0 ? <><div className="team-count mt-3 ">
								<div className="team-info pb-5 pt-3 ">
									<div className="count active mb-2" id="question1">
										<div className="d-flex align-items-center justify-content-between">
											<span><i className="fa-solid fa-list-check"></i></span>
											<h6>Active Team</h6>
											<h6>5</h6>
										</div>
									</div>
									<div className="count Leagues-team mb-2" id="question7">
										<div className="d-flex align-items-center justify-content-between">
											<span><i className="fa-solid fa-medal"></i>
											</span>
											<h6>Leagues </h6>
											<h6>{data?.Stats?.leagues}</h6>
										</div>

									</div>

									<div className="count tournament-team mb-2" id="question2">
										<div className="d-flex align-items-center justify-content-between">
											<span><i className="fa-solid fa-trophy"></i></span>
											<h6> Tournament</h6>
											<h6>{data?.Stats?.tournaments}</h6>
										</div>

									</div>

									<div className="count enroll mb-2" id="question3">
										<div className="d-flex align-items-center justify-content-between">
											<span><i className="fa-solid fa-users"></i></span>
											<h6> Enroll Team </h6>
											<h6>{data?.Stats?.EnrollTeam}</h6>
										</div>
									</div>

									<div className="count Upcoming mb-2" id="question4">
										<div className="d-flex align-items-center justify-content-between">
											<span><i className="fa-solid fa-calendar-check"></i></span>
											<h6>Upcoming </h6>
											<h6>{data?.Stats?.Upcoming}</h6>
										</div>

									</div>
									<div className="count mb-2" id="question5">
										<div className="d-flex align-items-center justify-content-between">
											<span><i className="fa-solid fa-spinner"></i> </span>
											<h6> Ongoing</h6>
											<h6>{data?.Stats?.Ongoing}</h6>
										</div>

									</div>

									<div className="count complete mb-2" id="question6">
										<div className="d-flex align-items-center justify-content-between">
											<span><i className="fa-solid fa-circle-check"></i></span>
											<h6> Completed</h6>
											<h6>{data?.Stats?.Completed}</h6>
										</div>
									</div>

								</div>
								<div className=" tournament-views  " id="answer">
									<FirstCard data={data?.games} margin={0} />
								</div>

							</div></> : <>
{
	data ==null ?<><Container><Loader/> </Container> </> :<><Container><div className='no-view-details-page'>
									<div className='image-details'>
										<div className='no-image'>
											<img src={detailImg} />
										</div>
										<div className='no-image'>
											<img src={detailImg} />
										</div>
									</div>
									<div className='content-view-details'>
										<div className='text-center'><p>Stay tuned for the Upcoming League/Tournaments</p>
											<p className='text-success'>Please Visit Again!</p></div>

									</div>


								</div> 	</Container></> 
}
								 </>
						}

					</div>
				</Container>
			</section>
			<Footer />
		</>

	)
}

export default ViewDetail;