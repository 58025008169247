import React, { useEffect, useState, useRef } from 'react';
import Header from '../../Component/Header/header';
import Footer from '../../Component/Footer/footer';
import { Image, Container, Row, Col, Accordion, Form, Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner'
import { AboutImage1, AboutImage2, AboutImage3, AboutImage4, StadiumImage, TabImage, Tick, PlayStore, AppleStore, CoachVideo, PlayerImage, Gallery1, Gallery2, Gallery3, Gallery4, Gallery5, Gallery6, LogoGallery1, LogoGallery2, LogoGallery3, LogoGallery4, LogoGallery5, LogoGallery6, LogoGallery7, LogoGallery8, LogoGallery9, LogoGallery10, HeaderLogo, TickGiff } from '../../Img/img';
import { FirstCard } from '../Component/TournamentCard/tournamentCard';
import DownloadApp from '../Component/DownloadApp/downloadApp';
import { NavLink } from 'react-router-dom';
import Swiper from 'swiper/bundle';
import { APIGet, APIPost } from "../../helpers/api";
import ScrollToTopButton from '../Component/ScrollButton/scrollButton';
import NoLeaguesTournaments from '../../Img/images/No-Leagues-Tournaments.png'
// import Form from 'react-bootstrap/Form';
import './homepage.css';
import Video from "../../Img/images/giu-video.png"
import ReCAPTCHA from 'react-google-recaptcha';
import Loader from '../../Component/Loader';


const Homepage = (props) => {
	const [submissionStatus, setSubmissionStatus] = useState(null)
	const [recaptchaValue, setRecaptchaValue] = useState(null);
	useEffect(() => {
		getStateCountry()
		const swiper = new Swiper(".mySwiper", {
			slidesPerView: 1,
			spaceBetween: 30,
			freeMode: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},

			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
				640: {
					slidesPerView: 1,
					spaceBetween: 20,
				},
				768: {
					slidesPerView: 2,
					spaceBetween: 40,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 50,
				},
			},
		});


		// Cleanup Swiper instance on component unmount
		return () => {
			if (swiper && swiper.destroy) {
				swiper.destroy();
			}
		};
	}, []);
	// Api Integration

	const [data, setData] = useState(null)
	const [loading, setLoading] = useState(true)
	const [spinner, setSpinner] = useState(false)
	const [spinner1, setSpinner1] = useState(false)
	const [state, setState] = useState([]);

	const [formData, setFormData] = useState({
		keyword: '',
		Title: '',
		Type: '',
		Start_Date: '',
		End_Date: '',
		state: '',
		city: ''
	});

	const [contactFormData, setContactFormData] = useState({
		firstname: '',
		lastname: '',
		email: '',
		contact_number: '',
		message: ''
	});


	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const getStateCountry = async () => {
		try {
			const baseUrl = process.env.REACT_APP_BASE_URL;
			const response = await APIGet(baseUrl, 'get-state-city', {})
			setState(response.data);
		} catch (error) {
			console.error('Error:', error);
		}
	}

	const handleSearch = async (e) => {
		e.preventDefault()
		setSpinner(true)
		const selectedType = formData.Type === '1' ? 'T' : formData.Type === '2' ? 'F' : '';
		const keyword = formData.keyword === 'complete' ? 'Complete' : formData.keyword === 'upcoming' ? 'Upcoming' : 'Current';
		console.log('Request Payload:', JSON.stringify({ filter: formData }));

		try {
			const baseUrl = process.env.REACT_APP_BASE_URL;
			const stateItem = state.find(item => item.selected_State.isoCode === formData.state);
			console.log(stateItem?.selected_State_name, "stateItem")
			const response = await APIPost(baseUrl, 'get-games-Managed', {
				filter: {
					keyword: keyword,
					Title: formData.Title,
					Type: selectedType,
					Start_Date: formData.Start_Date,
					End_Date: formData.End_Date,
					state: stateItem?.selected_State_name || '',
					city: formData.city
				},
				Organizer_id: 0,
				Page_Number: 1,
				Page: 'O',
			})
			setData(response.data.data);
			setSpinner(false)

		} catch (error) {
			setSpinner(false)
			console.error('Error:', error);
		}
	};

	useEffect(() => {
		setFormData({ ...formData, city: '' })
	}, [formData.state])





	useEffect(() => {
		async function callApi() {
			try {
				const baseUrl = process.env.REACT_APP_BASE_URL; // Get the base URL from .env
				const response = await APIPost(baseUrl, 'get-games-Managed', {
					"filter": {
						"keyword": "",
						"Title": "",
						"Type": "",
						"Start_Date": "",
						"End_Date": ""
					},
					"Organizer_id": 0,
					"Page_Number": 1,
					"Page": "H"
				});
				setData(response.data.data);
				setLoading(false);
			} catch (error) {
				console.error("Data not shown", error);
				setLoading(false);
			}
		}

		if (loading) {
			callApi();
		}
	}, [loading]);
	const aboutRef = useRef(null);
	const organizerRef = useRef(null);
	const coachRef = useRef(null);
	const playersRef = useRef(null);
	const contactRef = useRef(null);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		// If there's a hash in the URL, scroll to the corresponding section
		const hash = window.location.hash;
		const targetRef = getTargetRef(hash);
		if (targetRef) {
			window.scrollTo({
				top: targetRef.current.offsetTop,
				behavior: 'smooth',
			});
		}
	}, []);

	const getTargetRef = (hash) => {
		switch (hash) {
			case '#about':
				return aboutRef;
			case '#organizer':
				return organizerRef;
			case '#coach':
				return coachRef;
			case '#players':
				return playersRef;
			case '#contact':
				return contactRef;
			default:
				return null;
		}
	};

	const handleNavLinkClick = (hash) => {
		const targetRef = getTargetRef(hash);
		if (targetRef) {
			window.scrollTo({
				top: targetRef.current.offsetTop,
				behavior: 'smooth',
			});
		}
	};

	const validateForm = () => {
		console.log(contactFormData.firstname, 'contactFormData.firstname');
		const newErrors = {};

		if (!contactFormData.firstname) {
			newErrors.firstname = "Please enter firstname";
		}

		if (!contactFormData.lastname) {
			newErrors.lastname = "Please enter lastname";
		}

		if (!contactFormData.email || !/^\S+@\S+\.\S+$/.test(contactFormData.email)) {
			newErrors.email = "Please enter a valid email address";
		}

		if (!contactFormData.message) {
			newErrors.message = "Please enter Message";
		}
		if (!contactFormData.contact_number) {
			newErrors.contact_number = "Please enter Contact Number";
		}
		if (!recaptchaValue) {
			newErrors.captcha = "Please Complete The reCAPTCHA verification.";
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	//Contact Form-Submit

	const handleSubmit = async (event) => {

		event.preventDefault();


		if (validateForm()) {
			const formData = {
				firstname: contactFormData.firstname,
				lastname: contactFormData.lastname,
				email: contactFormData.email,
				contact_number: contactFormData.contact_number,
				message: contactFormData.message
			};
			setSpinner1(true)
			try {
				const baseUrl = process.env.REACT_APP_BASE_URL; // Get the base URL from .env
				console.log(baseUrl, 'baseUrlbaseUrl')
				const url = `${baseUrl}contactUs`;

				const response = await APIPost(baseUrl, 'contactUs', formData);
				console.log(response, 'responce')

				if (response.statusText == 'OK'
				) {
					setSubmissionStatus('success')
					setSpinner1(false)
					console.log('Server response:', response);
				} else {
					console.error('Error submitting form:', response);
					setSubmissionStatus('error');
					setSpinner1(false)
					// setSubmitMessage('An error occurred during form submission.');
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				setTimeout(() => {
					setContactFormData({
						firstname: '',
						lastname: '',
						email: '',
						contact_number: '',
						message: ''
					})
					setSubmissionStatus(null)
				}, 3000);
			} catch (error) {
				console.error('Error submitting form:', error);
				//   setSubmitMessage('An error occurred during form submission.');
			}

		}
	}
	const handleContactChange = (event) => {
		const { name, value } = event.target;
		setContactFormData((prevContactFormData) => ({
			...prevContactFormData,
			[name]: value,
		}));
	};

	console.log(state, "state")

	const scrollToSection = (ref) => {
		if (ref && ref.current) {
			window.scrollTo({
				top: ref.current.offsetTop,
				behavior: 'smooth',
			});
		}
	};

	return (
		<>
			<ScrollToTopButton />
			<Header onNavLinkClick={handleNavLinkClick} />
			<section className="banner-arera pb-5">
				<Container>
					<Row className="reverse_section">
						<Col xxl="6" lg="6" md="12" xs="12">
							<div className="banner-heading">
								<div className='banner-title'>
									<h3 className="spacing "> The Sports Schedule <strong>Maker</strong></h3>
									<h3 className="spacing pb-4"> That Helps You <strong>Grow </strong></h3>
								</div>
								<div className='startup-video'>
									<img src={Video} />

								</div>

								<div className='create-tournamet-ticks mt-4'>
									<li><span className='me-2'><svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1.88635 9.45811C2.99981 9.43221 3.93916 9.81057 4.82933 10.3407C5.85459 10.9519 6.74771 11.7299 7.59231 12.5663C7.81756 12.7893 8.03854 13.0165 8.26019 13.2431C8.40642 13.3926 8.40773 13.3949 8.52314 13.2103C9.05593 12.3575 9.60807 11.5175 10.173 10.6857C11.3799 8.90761 12.6589 7.18333 14.0698 5.56102C15.4973 3.91937 17.0347 2.39345 18.8452 1.16787C19.5872 0.665567 20.3777 0.262612 21.2613 0.066545C21.6564 -0.0209968 22.0518 -0.0173902 22.4488 0.0494957C22.4908 0.0567089 22.5462 0.0455612 22.5662 0.0911354C22.5918 0.149497 22.5315 0.17671 22.5 0.209169C22.1272 0.593435 21.7475 0.971144 21.3793 1.36C19.796 3.03149 18.3196 4.79446 16.9049 6.60923C14.8098 9.29745 12.8835 12.1008 11.153 15.0382C10.2373 16.5926 9.39725 18.187 8.61035 19.81C8.60085 19.8297 8.59265 19.8503 8.58084 19.8684C8.55002 19.9169 8.54445 20.0041 8.47724 19.9998C8.41461 19.9959 8.40379 19.9123 8.37822 19.8602C8.26773 19.6343 8.16051 19.4071 8.05231 19.1805C7.14903 17.2906 6.0018 15.5614 4.63064 13.9791C3.52112 12.6988 2.30537 11.5326 0.927329 10.5417C0.670277 10.3568 0.400438 10.1896 0.137813 10.0122C0.0853532 9.9768 -0.0035001 9.95746 0.000106488 9.88664C0.00436882 9.80729 0.0984681 9.79352 0.159452 9.765C0.494865 9.60729 0.854869 9.54041 1.21913 9.4968C1.45783 9.46827 1.6975 9.4486 1.88668 9.45877L1.88635 9.45811Z" fill="#27AE60" />
									</svg></span>Create and share tournament/League schedules.   </li>

									<div className='benifit-list mt-2'>
										<li className='me-2'> <span className='me-2'><svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1.88635 9.45811C2.99981 9.43221 3.93916 9.81057 4.82933 10.3407C5.85459 10.9519 6.74771 11.7299 7.59231 12.5663C7.81756 12.7893 8.03854 13.0165 8.26019 13.2431C8.40642 13.3926 8.40773 13.3949 8.52314 13.2103C9.05593 12.3575 9.60807 11.5175 10.173 10.6857C11.3799 8.90761 12.6589 7.18333 14.0698 5.56102C15.4973 3.91937 17.0347 2.39345 18.8452 1.16787C19.5872 0.665567 20.3777 0.262612 21.2613 0.066545C21.6564 -0.0209968 22.0518 -0.0173902 22.4488 0.0494957C22.4908 0.0567089 22.5462 0.0455612 22.5662 0.0911354C22.5918 0.149497 22.5315 0.17671 22.5 0.209169C22.1272 0.593435 21.7475 0.971144 21.3793 1.36C19.796 3.03149 18.3196 4.79446 16.9049 6.60923C14.8098 9.29745 12.8835 12.1008 11.153 15.0382C10.2373 16.5926 9.39725 18.187 8.61035 19.81C8.60085 19.8297 8.59265 19.8503 8.58084 19.8684C8.55002 19.9169 8.54445 20.0041 8.47724 19.9998C8.41461 19.9959 8.40379 19.9123 8.37822 19.8602C8.26773 19.6343 8.16051 19.4071 8.05231 19.1805C7.14903 17.2906 6.0018 15.5614 4.63064 13.9791C3.52112 12.6988 2.30537 11.5326 0.927329 10.5417C0.670277 10.3568 0.400438 10.1896 0.137813 10.0122C0.0853532 9.9768 -0.0035001 9.95746 0.000106488 9.88664C0.00436882 9.80729 0.0984681 9.79352 0.159452 9.765C0.494865 9.60729 0.854869 9.54041 1.21913 9.4968C1.45783 9.46827 1.6975 9.4486 1.88668 9.45877L1.88635 9.45811Z" fill="#27AE60" />
										</svg></span> Easy to use.</li>

										<li className='me-2'> <span className='me-2'><svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1.88635 9.45811C2.99981 9.43221 3.93916 9.81057 4.82933 10.3407C5.85459 10.9519 6.74771 11.7299 7.59231 12.5663C7.81756 12.7893 8.03854 13.0165 8.26019 13.2431C8.40642 13.3926 8.40773 13.3949 8.52314 13.2103C9.05593 12.3575 9.60807 11.5175 10.173 10.6857C11.3799 8.90761 12.6589 7.18333 14.0698 5.56102C15.4973 3.91937 17.0347 2.39345 18.8452 1.16787C19.5872 0.665567 20.3777 0.262612 21.2613 0.066545C21.6564 -0.0209968 22.0518 -0.0173902 22.4488 0.0494957C22.4908 0.0567089 22.5462 0.0455612 22.5662 0.0911354C22.5918 0.149497 22.5315 0.17671 22.5 0.209169C22.1272 0.593435 21.7475 0.971144 21.3793 1.36C19.796 3.03149 18.3196 4.79446 16.9049 6.60923C14.8098 9.29745 12.8835 12.1008 11.153 15.0382C10.2373 16.5926 9.39725 18.187 8.61035 19.81C8.60085 19.8297 8.59265 19.8503 8.58084 19.8684C8.55002 19.9169 8.54445 20.0041 8.47724 19.9998C8.41461 19.9959 8.40379 19.9123 8.37822 19.8602C8.26773 19.6343 8.16051 19.4071 8.05231 19.1805C7.14903 17.2906 6.0018 15.5614 4.63064 13.9791C3.52112 12.6988 2.30537 11.5326 0.927329 10.5417C0.670277 10.3568 0.400438 10.1896 0.137813 10.0122C0.0853532 9.9768 -0.0035001 9.95746 0.000106488 9.88664C0.00436882 9.80729 0.0984681 9.79352 0.159452 9.765C0.494865 9.60729 0.854869 9.54041 1.21913 9.4968C1.45783 9.46827 1.6975 9.4486 1.88668 9.45877L1.88635 9.45811Z" fill="#27AE60" />
										</svg></span>Powerful.</li>

										<li className='me-2'> <span className='me-2'><svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1.88635 9.45811C2.99981 9.43221 3.93916 9.81057 4.82933 10.3407C5.85459 10.9519 6.74771 11.7299 7.59231 12.5663C7.81756 12.7893 8.03854 13.0165 8.26019 13.2431C8.40642 13.3926 8.40773 13.3949 8.52314 13.2103C9.05593 12.3575 9.60807 11.5175 10.173 10.6857C11.3799 8.90761 12.6589 7.18333 14.0698 5.56102C15.4973 3.91937 17.0347 2.39345 18.8452 1.16787C19.5872 0.665567 20.3777 0.262612 21.2613 0.066545C21.6564 -0.0209968 22.0518 -0.0173902 22.4488 0.0494957C22.4908 0.0567089 22.5462 0.0455612 22.5662 0.0911354C22.5918 0.149497 22.5315 0.17671 22.5 0.209169C22.1272 0.593435 21.7475 0.971144 21.3793 1.36C19.796 3.03149 18.3196 4.79446 16.9049 6.60923C14.8098 9.29745 12.8835 12.1008 11.153 15.0382C10.2373 16.5926 9.39725 18.187 8.61035 19.81C8.60085 19.8297 8.59265 19.8503 8.58084 19.8684C8.55002 19.9169 8.54445 20.0041 8.47724 19.9998C8.41461 19.9959 8.40379 19.9123 8.37822 19.8602C8.26773 19.6343 8.16051 19.4071 8.05231 19.1805C7.14903 17.2906 6.0018 15.5614 4.63064 13.9791C3.52112 12.6988 2.30537 11.5326 0.927329 10.5417C0.670277 10.3568 0.400438 10.1896 0.137813 10.0122C0.0853532 9.9768 -0.0035001 9.95746 0.000106488 9.88664C0.00436882 9.80729 0.0984681 9.79352 0.159452 9.765C0.494865 9.60729 0.854869 9.54041 1.21913 9.4968C1.45783 9.46827 1.6975 9.4486 1.88668 9.45877L1.88635 9.45811Z" fill="#27AE60" />
										</svg></span>Customizable.</li>

									</div>

								</div>

								<div className="banner-btn mt-5">
									<NavLink to="https://app.giuteamconnect.com/" target="_blank"><button
										className="tounament me-2">Schedule a Tournament</button></NavLink>
									<NavLink to="https://app.giuteamconnect.com/" target="_blank"><button
										className="league start">Schedule a League</button></NavLink>
								</div>
							</div>
						</Col>
						<Col xxl="6" lg="6" md="12" xs="12">
							<div className='find-tournaments-legues'>
								<div className='Tournament-box'>
									<h4>Find Leagues / Tournaments</h4>

									<Form>

										<Form.Group className="mb-3 mt-3" controlId="text">
											<Form.Label>Title</Form.Label>
											<Form.Control type="text" placeholder="Title" name="Title" value={formData.Title} onChange={handleInputChange} />
										</Form.Group>

										<Row className="mb-3">
											<Form.Group as={Col} controlId="formGridDate">
												<Form.Label>Start Date</Form.Label>
												<Form.Control type="date" name="Start_Date" value={formData.Start_Date} onChange={handleInputChange} />
											</Form.Group>

											<Form.Group as={Col} controlId="formGridDate">
												<Form.Label>End Date</Form.Label>
												<Form.Control type="date" name="End_Date" value={formData.End_Date} onChange={handleInputChange} />
											</Form.Group>
										</Row>

										<Row className="mb-3">

											<Form.Group as={Col} controlId="formGridState">
												<Form.Label>State</Form.Label>
												<Form.Select defaultValue="Choose..." name="state" value={formData.state} onChange={handleInputChange}>
													<option value=''>Choose...</option>
													{state && Array.from(new Set(state.map(item => item.selected_State.isoCode))).map(isoCode => {
														const stateItem = state.find(item => item.selected_State.isoCode === isoCode);
														return (
															<option key={stateItem.selected_State.isoCode} value={stateItem.selected_State.isoCode}>{stateItem.selected_State_name}</option>
														);
													})}
												</Form.Select>
											</Form.Group>

											<Form.Group as={Col} controlId="formGridCity">
												<Form.Label>City</Form.Label>
												<Form.Select defaultValue="Choose..." name="city" value={formData.city} onChange={handleInputChange}>
													<option value=''>Choose...</option>
													{state && formData.state && state.filter(item => item.selected_State.isoCode === formData.state)?.map(item =>
														<option key={item.selected_City_name} value={item.selected_City_name}>
															{item.selected_City_name}
														</option>
													)}
												</Form.Select>
											</Form.Group>
										</Row>


										<Button variant="primary" type="submit" className='w-100 mt-5' onClick={handleSearch}>

											{spinner ? <> <Spinner animation="border" role="status">
											</Spinner></> : <> Find Leagues/Tournaments </>}
										</Button>

									</Form>

								</div>

							</div>
						</Col>
					</Row>
				</Container>
			</section>


			<section className="tournamnt-slider">
				<>{data == null ? <> <Container><Loader/> </Container> </> : <>{data.games.length == 0 ? <>
					<Container>
						<div className='no-league-history'>
							<div className="col-lg-12 text-center">
								<h3>More  Leagues/Tournaments</h3>
							</div>
							<div className='noLeagues-Tournaments'>
								<img src={NoLeaguesTournaments} />
							</div>
						</div>
					</Container>
				</> : <><Container>
					<Row className="align-items-center">
						<div className="col-lg-11 text-center">
							<h3>More  Leagues/Tournaments</h3>
						</div>
						<div className="col-lg-1 text-center">
							<NavLink className="a-link" to="/leagues-tournaments">View all</NavLink>
						</div>
					</Row>
					<div className="d-flex justify-content-center">
						<div className="swiper mySwiper">
							<div className="swiper-wrapper">
								<FirstCard data={data?.games?.Length > 5 ? data?.games?.slice(0, 5) : data?.games} />
							</div>
							<div className="swiper-pagination"></div>
						</div>
					</div>
				</Container></>}	</>} </>
			</section>


			<section ref={organizerRef} className="organizer pt-4  mb-5 " id="organizer">
				<Container>
					<div className="heading-area text-center ">
						<h1>ORGANIZER</h1>
						<p>Easily Create, Manage, and Schedule Leagues & Tournaments </p>

					</div>
					<Row>
						<Col sm="12" lg="6" md="12" className="mt-5">
							<div className="content-text mt-5">
								{/* <h4>Manage your team and players in the App</h4> */}
								<div className="text mt-4">
									<p>Revolutionize your league management experience with Team Connect. As a league organizer,
										take command of your scheduling needs effortlessly. Team Connect empowers you to
										seamlessly schedule leagues, set up tournaments, and establish home fields with unmatched
										convenience.
									</p>

									<p>No more wrestling with spreadsheets or drowning in paperwork. Team Connect automates the
										scheduling process, giving you more time to focus on the game. Tailor league formats, specify
										match preferences, and generate dynamic schedules effortlessly to suit your unique
										requirements.
									</p>
									<p>Team Connect isn't just about scheduling – it's a comprehensive sports management hub.
										Manage home fields effortlessly, keeping track of venue availability for a smooth playing
										experience. What sets us apart is our integrated payment system within the app. Bid farewell to
										cumbersome payment methods; Team Connect allows you to securely and conveniently
										process fees, registrations, and more, all from a single platform.</p>
								</div>

								<ul className="buiness-bullets">
									<li style={{ listStyleImage: `url(${Tick})`, backgroundRepeat: 'no-repeat', objectFit: 'cover' }}> Schedules
										Game(League/Tournament)</li>

									<li style={{ listStyleImage: `url(${Tick})`, backgroundRepeat: 'no-repeat', objectFit: 'cover' }}>Manage Games
										(Round Robin, Single or Double Elimination )
									</li>

									<li style={{ listStyleImage: `url(${Tick})`, backgroundRepeat: 'no-repeat', objectFit: 'cover' }}>Manage Venue, Fields and
										TimeSlots
									</li>
									<li style={{ listStyleImage: `url(${Tick})`, backgroundRepeat: 'no-repeat', objectFit: 'cover' }}>Manage Teams
										(Create/Enroll)
									</li>
									<li style={{ listStyleImage: `url(${Tick})`, backgroundRepeat: 'no-repeat', objectFit: 'cover' }}> Manage Members (Coach,
										Players, referee, Team Moms)


									</li>
									<li style={{ listStyleImage: `url(${Tick})`, backgroundRepeat: 'no-repeat', objectFit: 'cover' }}>Accept Payments
									</li>

									<li style={{ listStyleImage: `url(${Tick})`, backgroundRepeat: 'no-repeat', objectFit: 'cover' }}>Reminders (Games,
										Locations)
									</li>
								</ul>

								<div className="banner-btn mt-5">
									<NavLink to="https://app.giuteamconnect.com/" target="_blank"><button
										className="tounament me-2 mb-2 ms-2"> Schedule a Tournament</button></NavLink>

									<NavLink to="https://app.giuteamconnect.com/" target="_blank"> <button
										className="league start ms-2">Schedule a League</button></NavLink>

								</div>
							</div>
						</Col>
						<Col sm="12" lg="6" md="12" className="mt-5">
							<div className='organizer-dashboard'>
								<div className="coach-img mt-5 ">
									<Image src={TabImage} />
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>

			<section ref={coachRef} className="coach pt-4" id="coach">
				<Container>
					<div className="heading-area text-center ">
						<h1>COACH</h1>
						<p>With Team Connect, easily view your practice/game schedule, share game footage, and
							communicate with your team all in one place
						</p>
					</div>
					<Row>
						<Col lg="6" md="12" sm="12" className="mt-5">
							<div className="content-text mt-5">
								{/* <h4>Manage your team and players in the App</h4> */}

								<p>Gain unparalleled control over every facet of your team's journey, from game management to
									effective communication with all team members.
								</p>
								<p>Bid farewell to the hassle of coordinating schedules and managing player performance
									manually. Team Connect allows you to seamlessly handle games, tweak strategies, and ensure
									your team is always in top form. Our user-friendly interface puts comprehensive team
									management at your fingertips.</p>

								<p>Communication is paramount in sports, and Team Connect ensures you stay effortlessly
									connected with your team members. Foster seamless interaction, share crucial updates, and
									motivate your players with ease. The app's real-time communication features make
									collaboration straightforward, building a sense of unity among team members</p>


								<ul className="buiness-bullets">
									<li style={{ listStyleImage: `url(${Tick})` }}>Create Teams / Join Team
									</li>
									<li style={{ listStyleImage: `url(${Tick})` }}>Upload Sports Videos
									</li>
									<li style={{ listStyleImage: `url(${Tick})` }}> Schedules
										Games (League/Tournament)  </li>


									<li style={{ listStyleImage: `url(${Tick})` }}> Create/Join Team
										Chat Group
									</li>
									<li style={{ listStyleImage: `url(${Tick})` }}> Manage Participants
										(Add/Remove )
									</li>
								</ul>
								<div className="download-app mt-5">
									<NavLink to="https://play.google.com/store/apps/details?id=com.giufootball.teamconnect"
										target="_blank"> <Image src={PlayStore} /></NavLink>
									<NavLink to="https://apps.apple.com/us/app/giu-team-connect/id6452012682" target="_blank">
										<Image src={AppleStore} /></NavLink>
								</div>
							</div>

						</Col>
						<div className="col-lg-6 col-md-12 col-sm-12 mt-5 ">
							<div className="coach-img mt-4">
								<Image src={CoachVideo} />
							</div>

						</div>
					</Row>
				</Container>
			</section>

			<section ref={playersRef} className="player pb-5 pt-4" id="players">
				<Container>
					<div className="heading-area text-center">
						<h1>PLAYERS</h1>
						<p>Team Connect streamlines player experience with easy entry, coach communication, schedule
							visibility, video access, and personalized chat groups for seamless interaction.
						</p>
					</div>
					<Row>
						<div className="col-lg-6 mt-5">
							<div className="coach-img me-5 ">
								<Image className="img-fluid" src={PlayerImage} />
							</div>
						</div>
						<div className="col-lg-6 mt-5 pt-5">
							<div className="content-text mt-5 ">
								<h4>Manage your team and players in the App</h4>
								<p>
									Immerse yourself in a player-centric sports experience with Team Connect – the ultimate
									platform designed for you. Joining the team has never been smoother with our QR code
									scanner, making entry seamless and hassle-free.</p>

								<p> Connect directly with your Coach or Team Mom through our intuitive chat feature. Receive
									real-time updates, share insights, and stay in the loop with your team effortlessly. Team Connect
									is your direct line to open communication, fostering a sense of unity among players.
									Stay ahead of the game by accessing and viewing your league and tournament schedules
									effortlessly. No more confusion – Team Connect provides a clear, concise overview of upcoming
									matches, ensuring you're always prepared.
								</p>

								<p>Dive into the excitement with our sports video feature. Whether it's game highlights or training
									clips, Team Connect brings the action to your fingertips. Enhance your game knowledge and
									share the thrill with your teammates.
								</p>

								<Row>
									<Col lg="8">
										<ul className="buiness-bullets">

											<li style={{ listStyleImage: `url(${Tick})` }}>Join Team using QR
												code scanner
											</li>
											<li style={{ listStyleImage: `url(${Tick})` }}>Communicate with
												Coach/Team Mom via Chat
											</li>
											<li style={{ listStyleImage: `url(${Tick})` }}> View Game
												Schedules (League/Tournament)
											</li>
											<li style={{ listStyleImage: `url(${Tick})` }}> View Sports
												Videos
											</li>
											<li style={{ listStyleImage: `url(${Tick})` }}> Create Personal
												Chat Group
											</li>

										</ul>
									</Col>
									<div className="download-app mt-5">
										<NavLink to="https://play.google.com/store/apps/details?id=com.giufootball.teamconnect"
											target="_blank"> <Image src={PlayStore} /></NavLink>
										<NavLink to="https://apps.apple.com/us/app/giu-team-connect/id6452012682" target="_blank">
											<Image src={AppleStore} /></NavLink>
									</div>
								</Row>
							</div>
						</div>
					</Row>
				</Container>
			</section>


			<section ref={aboutRef} className="connect-team pb-5 pt-5" id="about">
				<Container>
					<div className="heading-area text-center pb-5 pt-4">
						<h1>ABOUT TEAM CONNECT</h1>
					</div>

					<Row>
						<Col sm="12" lg="4" md="12">
							<div className="portal">
								<Image src={StadiumImage} />
								<h4>NEXTGEN. GAME <br />SCHEDULING PORTAL</h4>
								<div className="tournament-leak-btn mt-5">
									<NavLink to="/#contact" onClick={() => scrollToSection(contactRef)}> <button className="league start">Lets Connect</button></NavLink>
								</div>

								{/* href={"/#contact"} onClick={() => scrollToSection(contactRef)} */}
							</div>
						</Col>
						<Col lg="4" md="12" xs="12">
							<div className="team-connect-team mt-2">
								<h2 className="text-center">
									<b> Our Mission</b>
								</h2>
								<p className="text">At Gridiron University, we welcome coaches who are passionate about making a
									positive
									impact
									on the lives of others. Join our exclusive community of dedicated coaches and unlock a
									world
									of opportunities for growth, collaboration, and professional development.</p>

								<p><b> At Gridiron University, we welcome coaches who are passionate about making a positive
									impact
									on the lives of others. Join our exclusive
								</b></p>
							</div>
						</Col>
						<Col lg="4" md="12">
							<div className="team-connect-team mt-2">
								<h2 className="text-center"><b> Our Vision</b></h2>
								<p>At Gridiron University, we welcome coaches who are passionate about making a positive
									impact
									on the lives of others. Join our exclusive community of dedicated coaches and unlock a
									world
									of opportunities for growth, collaboration, and professional development.</p>

								<p><b> At Gridiron University, we welcome coaches who are passionate about making a positive
									impact
									on the lives of others. Join our exclusive
								</b></p>
							</div>
						</Col>
						<Col lg="12" className="mt-5">
							<Row>
								<Col lg="3" sm="6">
									<div className="detail mt-2">
										<Image src={AboutImage1} className="svg" />
										<h3>Join Groups</h3>
										<p>Unlimited groups for<br /> an Event</p>
									</div>

								</Col>

								<Col lg="3" sm="6">
									<div className="detail mt-2">
										<Image src={AboutImage2} />
										<h3>24/7 Support</h3>
										<p>Unlimited groups for<br /> an Event</p>
									</div>

								</Col>
								<Col lg="3" sm="6">
									<div className="detail mt-2">
										<Image src={AboutImage3} />
										<h3>Friendly Staff</h3>
										<p>Unlimited groups for<br /> an Event</p>
									</div>

								</Col>
								<Col lg="3" sm="6">
									<div className="detail mt-2">
										<Image src={AboutImage4} />
										<h3>Join Groups</h3>
										<p>Unlimited groups for<br /> an Event</p>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>


			{/* <section className="fqas">
				<Container>
					<div className="heading-area text-center">
						<h1>FAQ</h1>
						<p>The FAQ section aims to address inquiries and provide helpful information to users, eliminating
							the need <br /> for them to contact support or seek clarification elsewhere.</p>
					</div>
					<Row>
						<Col sm="2"></Col>
						<Col sm="8" className="mt-5">
							<Accordion defaultActiveKey="0">
								<Accordion.Item eventKey="0">
									<Accordion.Header>How long is the trial experience?</Accordion.Header>
									<Accordion.Body>
										Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
										incididunt ut
										labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
										ullamco
										laboris nisi ut aliquip ex ea commodo consequat.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="1">
									<Accordion.Header>How long is the trial experience?</Accordion.Header>
									<Accordion.Body>
										Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
										incididunt ut
										labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
										ullamco
										laboris nisi ut aliquip ex ea commodo consequat.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="2">
									<Accordion.Header>How long is the trial experience?</Accordion.Header>
									<Accordion.Body>
										Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
										incididunt ut
										labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
										ullamco
										laboris nisi ut aliquip ex ea commodo consequat.
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Col>
						<Col sm="2"></Col>
					</Row>
				</Container>

			</section> */}

			<section className="gallery mb-5 pb-5 ">
				<div className="heading-area text-center pb-2 ">
					<h1>Our Gallery</h1>
				</div>
				<div className="container slider-img">
					<div className="swiper mySwiper">
						<div className="swiper-wrapper">
							<div className="swiper-slide">
								<Image src={Gallery1} className="gallery-img" />
							</div>
							<div className="swiper-slide">
								<Image src={Gallery2} className="gallery-img" />
							</div>
							<div className="swiper-slide">
								<Image src={Gallery3} className="gallery-img" />
							</div>
							<div className="swiper-slide">
								<Image src={Gallery4} className="gallery-img" />
							</div>
							<div className="swiper-slide">
								<Image src={Gallery5} className="gallery-img" />
							</div>
							<div className="swiper-slide">
								<Image src={Gallery6} className="gallery-img" />
							</div>
						</div>
						<div className="dots-btn pb-2">
							<div className="swiper-button-next"></div>
							<div className="swiper-button-prev"></div>
							<div className="swiper-pagination"></div>
						</div>
					</div>
				</div>
			</section>

			<section className="logos pt-5 pb-5">
				<Container>
					<div className="heading">

					</div>
					<Row className="justify-content-center">
						<Col lg="2" md="6" sm="12" className="mt-2">
							<div className="logo-img">
								<Image src={LogoGallery1} className="img-fluid" />
							</div>

						</Col>
						<Col lg="2" md="6" sm="12" className="mt-2">
							<div className="logo-img">
								<Image src={LogoGallery2} className="img-fluid" />
							</div>
						</Col>

						<Col lg="2" md="6" sm="12" className="mt-2">
							<div className="logo-img">
								<Image src={LogoGallery3} className="img-fluid" />
							</div>

						</Col>
						<Col lg="2" md="6" sm="12" className="mt-2">
							<div className="logo-img">
								<Image src={LogoGallery4} className="img-fluid" />
							</div>

						</Col>
						<Col lg="2" md="6" sm="12" className="mt-2">
							<div className="logo-img">
								<Image src={LogoGallery5} className="img-fluid" />
							</div>
						</Col>

						<Col lg="2" md="6" sm="12" className="mt-2">
							<div className="logo-img">
								<Image src={LogoGallery6} className="img-fluid" />
							</div>

						</Col>
						<Col lg="2" md="6" sm="12" className="mt-2">
							<div className="logo-img">
								<Image src={LogoGallery7} className="img-fluid" />
							</div>
						</Col>
						<Col lg="2" md="6" sm="12" className="mt-2">
							<div className="logo-img image-down">
								<Image src={LogoGallery8} className="img-fluid" />
							</div>

						</Col>
						<Col lg="2" md="6" sm="12" className="mt-2">
							<div className="logo-img sticker-img">
								<Image src={LogoGallery9} className="img-fluid" />
							</div>

						</Col>

						<Col lg="2" md="6" sm="12" className="mt-2">
							<div className="logo-img image-down">
								<Image src={LogoGallery10} className="img-fluid" />
							</div>
						</Col>
					</Row>
				</Container>
			</section>

			<DownloadApp />

			<section ref={contactRef} className="contact  pt-5 pb-5 " id="contact">
				<Container>
					<Row>
						<div className="heading text-center pt-4">
							<h1> Contact us</h1>
							<p><b> Any Question or Remarks? Just Write a Message !</b></p>
						</div>
						<Col lg="12">
							<div className="conatct-box">
								<Row>
									<Col lg="5" md="12" sm="12">
										<div className="founder mt-3">
											<div className="contact-details text-center">
												<h2 className="mb-3 mt-2">More Infomation </h2>
												<Image src={HeaderLogo} />
											</div>
											<div className="founder-details-icon  ">
												<h5><b> Address</b></h5>
												<div className="location text-align-center ">
													<label className='location-dot-label'>  <i className="fa-solid fa-location-dot"></i></label>
													<NavLink to="https://www.google.com/maps/place/St.+George,+UT+84791,+USA/@37.090015,-113.5794839,15z/data=!3m1!4b1!4m10!1m2!2m1!1sPO+Box+911524,+St.+George,+UT,+84791!3m6!1s0x80ca5ac59d9f10af:0xf3f055b9952e8586!8m2!3d37.09!4d-113.56!15sCiRQTyBCb3ggOTExNTI0LCBTdC4gR2VvcmdlLCBVVCwgODQ3OTGSAQtwb3N0YWxfY29kZeABAA!16s%2Fm%2F07nq77w?entry=ttu"
														target="_blank">
														PO Box 911524, St. George, UT, 84791
													</NavLink>
												</div>
												<hr />
												<div className="phone">
													<h5><b> Phone</b></h5>
													<NavLink to="tel:+(385) 446-4448"><i className="fa-solid fa-phone"></i> <span
														className="ms-3">(385) 446-4448</span>
													</NavLink>
												</div>
												<hr />

												<div className="mail-us">
													<h5><b>E-mail</b></h5>
													<div className="location text-align-center "><label>
														<i className="fa-solid fa-envelope"></i></label>
														<NavLink to="mailto:info@giuteamconnect" target="_blank">
															info@giuteamconnect.com
														</NavLink> </div>

												</div>
											</div>
										</div>
									</Col>

									<Col lg="7" md="12" sm="12">
										{/* <div className="contact-form  mt-5"> */}
										{submissionStatus !== 'success' && submissionStatus !== 'error' && (
											<Form className="mt-3 form_homepage_landing">
												<Row>
													<Form.Group as={Col} className="mb-3 form-error" controlId="formBasicEmail">
														<Form.Label className="font-weight-bold">First Name</Form.Label>
														<Form.Control
															type="text"
															name="firstname"
															placeholder="Enter first name"
															value={contactFormData.firstname}
															onChange={handleContactChange}
															isInvalid={!!errors.firstname}
														/>
														<Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
													</Form.Group>
													<Form.Group as={Col} className="mb-2 form-error" controlId="formBasicEmail">
														<Form.Label className="font-weight-bold">Last Name</Form.Label>
														<Form.Control
															type="text"
															name="lastname"
															placeholder="Enter last Name"
															value={contactFormData.lastname}
															onChange={handleContactChange}
															isInvalid={!!errors.lastname}
														/>
														<Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>
													</Form.Group>
												</Row>
												<Form.Group as={Col} className="mb-2 form-error" controlId="formBasicPassword">
													<Form.Label className="font-weight-bold">Email</Form.Label>
													<Form.Control
														type="email"
														placeholder="Enter Email"
														name="email"
														value={contactFormData.email}
														onChange={handleContactChange}
														isInvalid={!!errors.email}
													/>
													<Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
												</Form.Group>
												<Row>
													<Form.Group as={Col} className="mb-2 form-error" controlId="formBasicPassword">
														<Form.Label className="font-weight-bold">Contact Number</Form.Label>
														<Form.Control
															type="number"
															placeholder="Enter Contact Number"
															name="contact_number"
															onChange={handleContactChange}
															value={contactFormData.contact_number}
															isInvalid={!!errors.contact_number}
														/>
														<Form.Control.Feedback type="invalid">{errors.contact_number}</Form.Control.Feedback>

													</Form.Group>
												</Row>
												<Form.Group className="mb-4  form-error" controlId="exampleForm.ControlTextarea1">
													<Form.Label className="font-weight-bold">Message</Form.Label>
													<Form.Control
														as="textarea"
														rows={3}
														name="message"
														value={contactFormData.message}
														onChange={handleContactChange}
														isInvalid={!!errors.message}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.message}
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group>
													<Form.Control isInvalid={!!errors.captcha} className='d-none'></Form.Control>
													<ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
														onChange={(value) => {
															setRecaptchaValue(value);
														}}
													/>
													<Form.Control.Feedback type="invalid">{errors.captcha}</Form.Control.Feedback>
												</Form.Group>

												<Button variant="primary" type="submit" className="mt-4" onClick={handleSubmit}>
													{spinner1 ? <> <Spinner animation="border" role="status">
													</Spinner></> : <>Send </>}
												</Button>
											</Form>
										)}
										{submissionStatus === 'success' && (
											<p className="form_subbmitted">
												<span>
													<Image className="img-fluid" src={TickGiff} />
												</span>
												Form submitted successfully!
											</p>
										)}
										{submissionStatus === "error" && <p className="form_subbmitted">Error submitting the form. Please try again later.</p>}
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</Container>
			</section >
			<Footer />
		</>
	)
}

export default Homepage;