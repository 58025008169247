/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Row, Col, Table, Form, Image, } from 'react-bootstrap';
import { usePDF } from 'react-to-pdf';
import { useParams } from 'react-router';
import { APIGet } from '../../helpers/api';
import defaultlogo from '../../Img/images/sunfootball_200.png';
import knok from '../../Img/images/roster-started.png';
import Logo from '../../Img/images/Logo.png';


import { Container, Button, Card } from 'react-bootstrap';

const RosterSheet = () => {
    const { toPDF, targetRef } = usePDF({ filename: 'Roster.pdf' });
    const [players, setPlayers] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState({});
    const [logo, setLogo] = useState('');
    const [code, setCode] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(''); // New error state
    const { roasterId } = useParams();

    const ref = useRef();

    const handleGetRoaster = () => {
        setError(''); // Clear any previous errors
        APIGet(process.env.REACT_APP_BASE_URL, `getRoaster/${roasterId}?code=${code}`)
            .then((res) => {
                const { team, players, logo } = res.data;

                setSelectedTeam(team);
                setPlayers(players);
                setLogo(logo);
                setSuccess(true);
            })
            .catch(() => {
                setError('Failed to fetch the roster. Please check the code and try again.'); // Set error message
            });
    };

    return (
        <>


            {/* <input onChange={(e) => setCode(e.target.value)} /> */}
            {/* <button disabled={!(code?.length > 0)} onClick={handleGetRoaster}>
        Get Sheet
      </button> */}

            {error && <p className="text-danger">{error}</p>} {/* Display the error message */}

            {
                success ? <div> <div className="container-fluid">
                    <div className="roaster-top-nav  text-white py-3" style={{ backgroundColor: '#484848' }}>
                        <div className='px-4 '>
                            <Row >
                                <Col md={3}>
                                </Col>
                                <Col md={6}>
                                    <div className='text-center d-flex  justify-content-center align-items-center' onClick={() => toPDF()} style={{ cursor: 'pointer' }}>

                                        <h4 className="mb-0 ">Certified Roster and Minimum Play Sheet</h4>
                                        <span> <svg width="60px" height="30px" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 3.01254C10.9983 2.46026 11.4446 2.01114 11.9969 2.00941C12.5492 2.00768 12.9983 2.45399 13 3.00627L11 3.01254Z" fill="#fff" />
                                            <path d="M14.3158 10.2951L13.0269 11.592L13 3.00627L11 3.01254L11.0269 11.5983L9.73003 10.3095C9.33828 9.92018 8.7051 9.92214 8.3158 10.3139C7.9265 10.7056 7.92849 11.3388 8.32024 11.7281L8.32275 11.7306L8.32374 11.7316L12.039 15.4236L15.7206 11.7187L15.7262 11.7131L15.727 11.7123L15.7278 11.7115L15.7337 11.7056L15.7344 11.7049L14.3158 10.2951Z" fill="#fff" />
                                            <path d="M15.7344 11.7049C16.1237 11.3131 16.1217 10.6799 15.73 10.2906C15.3382 9.90134 14.705 9.90335 14.3158 10.2951L15.7344 11.7049Z" fill="#fff" />
                                            <path d="M4 12C4 10.8954 4.89543 10 6 10C6.55228 10 7 9.55228 7 9C7 8.44771 6.55228 8 6 8C3.79086 8 2 9.79086 2 12V18C2 20.2091 3.79086 22 6 22H17C19.7614 22 22 19.7614 22 17V12C22 9.79086 20.2091 8 18 8C17.4477 8 17 8.44771 17 9C17 9.55228 17.4477 10 18 10C19.1046 10 20 10.8954 20 12V17C20 18.6569 18.6569 20 17 20H6C4.89543 20 4 19.1046 4 18V12Z" fill="#fff" />
                                        </svg></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

                    <div className="RosterSheet_background" >
                        <span ref={ref}>
                            <div className="RosterSheet  mt-5 pt-5" ref={targetRef}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    {/*  <img src={logo} alt="TeamConnect" className='object-fit-cover' height="110px" /> */}
                                </div>
                                <Row className="text-center mb-4">
                                    <Col md={3}>
                                        <div className='d-flex justify-content-center gap-1 align-items-center'>
                                            <div>
                                                <label htmlFor="logoUpload" style={{ cursor: 'pointer' }}>
                                                    <img
                                                        src={logo || defaultlogo}
                                                        alt="Sun Logo"
                                                        width="100"
                                                        height="88px"
                                                        className='object-fit-cover'
                                                    />

                                                </label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="text-center my-4">
                                            <h2><b>Certified Roster and Minimum Play Sheet</b></h2>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <div>
                                                <label htmlFor="logoUploadAlternate" style={{ cursor: 'pointer' }}>
                                                    <img
                                                        src={logo || defaultlogo}
                                                        alt="Sun Logo"
                                                        width="100"
                                                        height="88px"
                                                        className='object-fit-cover'
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <div className='Team-details '>
                                    <div className='team-form '>
                                        <Row className="mb-4">
                                            <Col md={12}>
                                                <div className='form-team '>
                                                    <Form>
                                                        <div className='border border-dark p-2 '>  <Form.Group as={Row} className="" controlId="formPlaintext">
                                                            <Form.Label column sm="2" className='text-dark'>
                                                                Team Name :
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                {selectedTeam?.team_name}
                                                                {/* <Form.Control type='text' /> */}

                                                            </Col>
                                                        </Form.Group>

                                                        </div>

                                                        <div className='border  border-dark p-2 '>
                                                            <Form.Group as={Row} className="" controlId="formPlaintext">
                                                                <Form.Label column sm="2" className='text-dark'>
                                                                    Coach Name :
                                                                </Form.Label>
                                                                <Col sm="6">
                                                                    <Form.Control type="text" placeholder="" />
                                                                </Col>
                                                            </Form.Group>
                                                        </div>
                                                        <div className='border border-dark p-2 ' >
                                                            <Form.Group as={Row} className="" controlId="formPlaintext">

                                                                <Form.Label column sm="4" className='text-dark' style={{ width: "400px" }}>
                                                                    Roster is only valid if signed & stamped
                                                                </Form.Label>

                                                                <Col sm="6">
                                                                    <Form.Control type='text' />
                                                                </Col>
                                                            </Form.Group>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    {/* Table Section */}
                                    <div className=' tracked_table'>
                                        <div className='text-center mb-3'> <h4><b> Plays must be tracked for only non-starters, minimum 10 plays</b></h4></div>
                                        <Row>
                                            <Col>
                                                <Table bordered striped>
                                                    <thead>
                                                        <tr className='border-top border-dark'>
                                                        <th className="important-th" > Jersey #</th>
                                                            <th className="important-th" >Name</th>
                                                            <th className="important-th " style={{width:'10%'}} >Weight</th>
                                                            {/* <th className="important-th" >#</th> */}
                                                            <th className="important-th" >X/Z</th>
                                                            <th colSpan="10" className="important-th" >10 Plays Tracker</th>
                                                            {/* <th  className="important-th" ></th> */}

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Sample Row */}
                                                       
                                                        {(players.length > 0 ? players : Array(15).fill({})).map((item, i) => (
                                                            <tr key={i}>
                                                                 <td className="important-th"  style={{width:'20%'}}> 
                                                                       <Form.Group>
                                                                        <Form.Control type="text" value={item?.jersey_no || '' } />
                                                                    </Form.Group>
                                                                         </td>

                                                                <td className="important-th">
                                                                    {item.firstname || ''}
                                                                    {/* <Form.Group>
                                                                        <Form.Control type="text" />
                                                                    </Form.Group> */}
                                                                </td>
                                                                <td className="important-th ">
                                                                    {item.weight || ''}
                                                                    {/* <Form.Group>
                                                                        <Form.Control type="text" />
                                                                    </Form.Group> */}
                                                                </td>
                                                                <td className="important-th"  style={{width:'10%'}}>

                                                                       <Form.Group>
                                                                        <Form.Control type="text" />
                                                                    </Form.Group>
                                                                </td>
                                                                {/* <td className="important-th"></td> */}
                                                                {/* <td className="important-th"></td> */}
                                                                {/* 10 Play Tracker */}
                                                                {[...Array(10)].map((_, index) => (
                                                                    <td className="important-th" key={index}>{index + 1}</td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className='feedbackform team-form'>
                                        <div className="border border-dark ">
                                            <h2 className="text-center mb-4 mt-2"><b>Ref Score Card</b></h2>
                                            <div className='form-team'>
                                                <Form>
                                                    {/* White Hat Name */}
                                                    <div className='border border-dark p-2 '>
                                                        <Form.Group as={Row} className="" controlId="whiteHatName">
                                                            <Form.Label column sm="2" className="font-weight-bold">
                                                                White Hat Name:
                                                            </Form.Label>
                                                            <Col sm="9">
                                                                <Form.Control type="text" placeholder="" />
                                                            </Col>
                                                        </Form.Group>
                                                    </div>

                                                    {/* Ref Score */}
                                                    <div className='border border-dark p-2 '>
                                                        <Form.Group as={Row} className="">
                                                            <Form.Label column sm="2" className="font-weight-bold">
                                                                Ref Score (Circle One):
                                                            </Form.Label>
                                                            <Col sm="9">
                                                                <div className="d-flex justify-content-around">
                                                                    <div className='text-center'>
                                                                        <span> <b>Good</b></span>
                                                                    </div>
                                                                    <div className='text-center'>
                                                                        <span><b> 2 Good</b></span>

                                                                    </div>

                                                                    <div className='text-center'>
                                                                        <span class> <b>3 Needs Work</b></span>

                                                                    </div>

                                                                </div>
                                                            </Col>
                                                        </Form.Group>
                                                    </div>

                                                    {/* Comments */}
                                                    <div className='border border-dark p-2 '>
                                                        <Form.Group as={Row} className="" controlId="comments">
                                                            <Form.Label column sm="2" className="font-weight-bold">
                                                                Comments:
                                                            </Form.Label>
                                                            <Col sm="9">
                                                                <Form.Control as="textarea" rows={5} />
                                                            </Col>
                                                        </Form.Group>
                                                    </div>

                                                    {/* Footer Text */}
                                                    <div className="text-center mt-4  pt-3">
                                                        <small>
                                                            *This sheet must be maintained during the game, available at
                                                            half-time, and turned into the field rep at the end of the game.*
                                                        </small>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </span>

                    </div>
                </div> : <Container fluid className="vh-100 d-flex justify-content-center align-items-center">
                    {/* Popup Box */}
                    <Card style={{ width: '50%', maxWidth: '748px', border: '0' }} className="shadow-lg">
                        <Row>
                            {/* Left side with image */}
                            <Col md={6} className="d-none d-md-block position-relative ">
                                <img
                                    src={knok}
                                    alt="Login"
                                    className="img-fluid"
                                    style={{ objectFit: 'cover', opacity: '76%' }}
                                />
                                {/* Overlay */}
                                <div className="overlay"></div>
                            </Col>

                            {/* Right side with input box and button */}
                            <Col md={6} className='p-4' >
                                <div className="d-flex flex-column justify-content-center align-items-center   ">
                                    <img src={Logo} alt="Logo" style={{ width: '100px', height: 'auto', }} />
                                </div>
                                <div className="d-flex flex-column justify-content-center align-items-center h-50  mt-5">
                                    <h3 className="mb-4">Enter Your Passcode</h3>
                                    <Form className="w-100">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter your code"
                                                value={code} // Bind the input value to the state
                                                onChange={(e) => setCode(e.target.value)} // Update state on input change
                                            />
                                        </Form.Group>

                                        <Button
                                            variant="primary"
                                            className="w-100 mt-3"
                                            onClick={handleGetRoaster}
                                            disabled={!(code?.length > 0)} // Disable button if no code is entered
                                        >
                                            View Roster
                                        </Button>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Container>




            }
        </>
    );
};

export default RosterSheet;
